<template>
  <div>
    <el-card shadow="always" class="box-card">
      <el-row>
        <el-col :span="3">简介</el-col>
        <el-col :span="21">
          <editor :init="init" v-model="companyIntro.info"></editor>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">详细介绍</el-col>
        <el-col :span="21">
          <el-input type="textarea" v-model="companyIntro.descDetail"  :autosize="{ minRows: 6, maxRows: 20}"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">详细介绍背景图</el-col>
        <el-col :span="21">
          <img-upload :url="companyIntro.descImg" @change="urlChange"></img-upload>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-button size="medium" type="primary" style="min-width: 120px" @click="submit">保存</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {getIntro, setIntro} from "@/plugins/api/company";
import ImgUpload from "@/components/ImgUpload";
import tinymce from 'tinymce/tinymce';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver'
import 'tinymce/icons/default'

export default {
  name: 'Intro',
  components: {
    ImgUpload,
    'editor': Editor
  },
  data() {
    return {
      init: {
        language_url: '/static/tinymce/lang/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/static/tinymce/skin/oxide',
        content_style: "p {margin: 0}",
        height: 300,
        branding: false,
        menubar: false,
        toolbar: 'undo redo | bold fontsizeselect forecolor',
      },
      companyIntro: {
        info: '',
        descDetail: '',
        descImg: '',
      }
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async getCompanyIntro() {
      let res = await getIntro();
      this.companyIntro = res[0];
    },
    urlChange(url) {
      this.companyIntro.descImg = url
    },
    async submit() {
      await setIntro({
        info: this.companyIntro.info,
        desc_detail: this.companyIntro.descDetail,
        desc_img: this.companyIntro.descImg,
      });
      this.$message.success('操作成功')
    }
  },
  created() {
    this.getCompanyIntro();
  },
  watch() {
  }
};
</script>

<style scoped>
.el-row {margin-bottom: 20px}
</style>
<style>

</style>
